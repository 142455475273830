import "core-js/modules/es.number.constructor.js";
import { computed, defineComponent, ref } from "vue";
export default defineComponent({
  name: "RichRateItem",
  props: {
    value: {
      type: Number,
      default: 0
    },
    count: {
      type: Number,
      default: 5
    },
    color: {
      type: String,
      default: "#FEC740"
    },
    type: {
      type: String,
      default: "number"
    }
  },
  emits: ["update:value"],
  setup: function setup(props, context) {
    var rate = ref();
    var activeCount = ref(props.value);

    var active = function active(count) {
      activeCount.value = count || 0;
      context.emit("update:value", activeCount.value);
    };

    var richRateHovering = ref(false);

    var richRateEnter = function richRateEnter() {
      richRateHovering.value = true;
      richRateItemCount.value = activeCount.value;
    };

    var richRateLeave = function richRateLeave() {
      richRateHovering.value = false;
      richRateItemCount.value = 0;
    };

    var richRateItemCount = ref(0);

    var richRateItemEnter = function richRateItemEnter(item) {
      richRateItemCount.value = item;
    };

    var itemClass = computed(function () {
      return function (count) {
        var isActive = !richRateHovering.value && activeCount.value >= count || richRateHovering.value && richRateItemCount.value >= count;

        if (props.type === "number") {
          return isActive ? "rich-rate-item-active" : "";
        } else {
          return "";
        }
      };
    });
    var itemStyle = computed(function () {
      return function (count) {
        var isActive = !richRateHovering.value && activeCount.value >= count || richRateHovering.value && richRateItemCount.value >= count;

        if (props.type === "number") {
          return {
            "border-color": isActive ? props.color : "#BFBFBF",
            "background-color": isActive ? props.color : "#FFFFFF",
            color: isActive ? "#FFFFFF" : "#BFBFBF"
          };
        } else {
          return {};
        }
      };
    });
    var itemStepStyle = computed(function () {
      return function (count) {
        var isActive = !richRateHovering.value && activeCount.value >= count || richRateHovering.value && richRateItemCount.value >= count;
        return {
          width: isActive ? "12px" : "1px",
          height: isActive ? "12px" : "4px",
          "border-left-width": isActive ? "1px" : "0",
          "border-top-width": isActive ? "1px" : "0",
          "border-bottom-width": isActive ? "1px" : "0",
          "border-color": isActive ? props.color : "#BFBFBF",
          "border-radius": isActive ? "100%" : "0",
          "background-color": "#FFFFFF",
          color: isActive ? "#FFFFFF" : "#BFBFBF"
        };
      };
    });
    return {
      rate: rate,
      activeCount: activeCount,
      active: active,
      richRateHovering: richRateHovering,
      richRateEnter: richRateEnter,
      richRateLeave: richRateLeave,
      richRateItemCount: richRateItemCount,
      richRateItemEnter: richRateItemEnter,
      itemClass: itemClass,
      itemStyle: itemStyle,
      itemStepStyle: itemStepStyle
    };
  }
});