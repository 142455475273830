import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-feb8e5da"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["onMouseenter", "onClick"];
var _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["rich-rate", [_ctx.type]]),
    onMouseenter: _cache[0] || (_cache[0] = function () {
      return _ctx.richRateEnter && _ctx.richRateEnter.apply(_ctx, arguments);
    }),
    onMouseleave: _cache[1] || (_cache[1] = function () {
      return _ctx.richRateLeave && _ctx.richRateLeave.apply(_ctx, arguments);
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.count, function (item, index) {
    return _openBlock(), _createElementBlock("span", {
      key: index,
      class: _normalizeClass(["rich-rate-item", _ctx.itemClass(item)]),
      style: _normalizeStyle(_ctx.itemStyle(item)),
      onMouseenter: function onMouseenter($event) {
        return _ctx.richRateItemEnter(item);
      },
      onClick: function onClick($event) {
        return _ctx.active(item);
      }
    }, [_ctx.type === 'number' ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item), 1)) : _ctx.type === 'step' ? (_openBlock(), _createElementBlock("span", {
      key: 1,
      class: "step-inner",
      style: _normalizeStyle(_ctx.itemStepStyle(item))
    }, null, 4)) : _createCommentVNode("", true)], 46, _hoisted_1);
  }), 128))], 34);
}