import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-04732d03"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "slider"
};
var _hoisted_2 = {
  key: 1,
  class: "num"
};
var _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_slider = _resolveComponent("a-slider");

  return _openBlock(), _createElementBlock("div", null, [$props.rateType === 'slider' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_slider, {
    marks: $setup.marks,
    step: null,
    max: $setup.markMax,
    min: $setup.markMin,
    tooltipVisible: false,
    onChange: $setup.changeSliderValue,
    value: $props.value - 1
  }, null, 8, ["marks", "max", "min", "onChange", "value"])])) : $props.rateType === 'num' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.marks, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["num-item", {
        'num-item-active': index <= $setup.checkIndex
      }]),
      key: index,
      onClick: function onClick($event) {
        return $setup.changeNumValue(index);
      }
    }, _toDisplayString(item), 11, _hoisted_3);
  }), 128))])) : _createCommentVNode("", true)]);
}