import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.keys.js";
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
export default {
  name: "SliderRate",
  props: {
    rateType: {
      type: String,
      default: "slider"
    },
    param: {
      type: Object,
      default: function _default() {
        return {
          interval: 1,
          max: 5,
          min: 1
        };
      }
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 0
    }
  },
  setup: function setup(props, context) {
    var marks = ref([]);
    var checkIndex = ref(props.value - 1);
    var markMax = ref(5);
    var markMin = ref(1);

    var markHandle = function markHandle() {
      var interval = props.param.interval;
      var max = props.param.max;
      var min = props.param.min;
      var result = [];

      function add(value) {
        result.push(value);
        value = Number((value + interval).toFixed(2));

        if (value <= max) {
          add(value);
        }
      }

      add(min);
      marks.value = result;
      markMin.value = Number(Object.keys(result)[0] || 1);
      markMax.value = Number(Object.keys(result)[Object.keys(result).length - 1]);
    }; // 滑块打分数值改变事件


    function changeNumValue(index) {
      if (props.disabled) return;
      checkIndex.value = index;
      context.emit("update:value", index + 1);
    } // 滑块打分数值改变事件


    function changeSliderValue(e) {
      if (props.disabled) return;
      context.emit("update:value", e + 1);
    }

    watch(function () {
      return props.param;
    }, function () {
      markHandle();
    }, {
      immediate: true
    });
    return {
      marks: marks,
      checkIndex: checkIndex,
      markMin: markMin,
      markMax: markMax,
      changeNumValue: changeNumValue,
      changeSliderValue: changeSliderValue
    };
  }
};